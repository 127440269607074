@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
  font-size: 10px;
  --black: #000;
  --white: #fff;
  --color-dark-primary: #1f2937; /* Dark Blue/Grey (adjusted) */
  --color-light-primary: #e8fff0; /* Light Green (adjusted) */
  --color-primary-green: #00ff88; /* Bright Green (adjusted) */
  --color-primary-blue: #005f5f; /* Teal (adjusted) */
  --color-secondary-green: #e8fff0;

  --color-secondary-blue: #e8f0ff;
  --color-primary-purple: #9c42f5;
  --color-slate-50: #f8fafc;
  --color-slate-100: #f1f5f9;
  --color-slate-200: #e2e8f0;
  --color-slate-300: #cbd5e1;
  --color-slate-400: #94a3b8;
  --color-slate-500: #64748b;
  --color-slate-600: #475569;
  --color-slate-700: #334155;
  --color-slate-800: #1e293b;
  --color-slate-900: #0f172a;
  --color-slate-950: #020617;
}

/* Light mode variables */
.light-mode {
  --color-slate-50: #ffffff;
  --color-slate-100: #f8f9fa;
  --color-slate-200: #e9ecef;
  --color-slate-300: #dee2e6;
  --color-slate-400: #ced4da;
  --color-slate-500: #adb5bd;
  --color-slate-600: #6c757d;
  --color-slate-700: #495057;
  --color-slate-800: #343a40;
  --color-slate-900: #212529;
  --color-slate-950: #161b22;
  --color-dark-primary: #ffffff;
  --color-light-primary: #1e1e1e;
  --error-red: #dc3545;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
html,
body {
  width: 100%;
  max-width: 100vw;
  background-color: var(--color-slate-950) !important;
}
.masteryhouse a {
  text-decoration: none;
}
.box {
  width: 100%;
  max-width: 1500px;
  padding: 0 10rem;
  margin: 0 auto;
}
.box-lg {
  width: 100%;
  max-width: 2500px;
  padding: 0 3.5rem;
  margin: 0 auto;
}
a {
  text-decoration: none;
}
img {
  object-fit: contain;
}
.theme-toggle-btn {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background-color: var(--color-slate-800);
}

.error {
  color: #ff4d4f; /* A bright red color for errors */
}
.gradient-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom right, #00ff88, #4579f5, #9c42f5);
  border-radius: 0.75rem;
  padding: 1.25rem 3.5rem;
  color: var(--white);
  font-size: 1.8rem;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 150%;
  transition: 0.35s;
  cursor: pointer;
  border: unset;
}
.gradient-btn:hover {
  filter: hue-rotate(30deg);
}
.gradient-text {
  background: linear-gradient(to right, #00ff88, #4579f5, #9c42f5);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  font-family: "Lato", sans-serif;
}

/* Gradient Border Button */
.gradient-border-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 3.5rem;
  color: var(--white);
  font-size: 1.8rem;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  transition: 0.35s;
  position: relative;
  border: 0 solid;
  border-radius: 1rem; /* Adding border radius */
  z-index: 1;
  overflow: hidden;
}

.gradient-border-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, #00ff88, #4579f5, #9c42f5);
  z-index: -1;
  transition: 0.35s;
  border-radius: 0.75rem;
}

.gradient-border-btn::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: var(
    --color-slate-900
  ); /* Adjust the inner background color */
  z-index: -1;
  border-radius: 0.75rem;
}

.gradient-border-btn:hover::before {
  filter: hue-rotate(30deg);
}

.gradient-border-btn:hover {
  color: var(--white);
}

.homepage {
  width: 100%;
  overflow: hidden;
  max-width: 100vw;
  position: relative;
}
.circle-1 {
  width: 95rem;
  height: 95rem;
  border-radius: 50%;
  background: rgba(155, 66, 245, 0.2);
  filter: blur(300px);
  position: absolute;
  right: -20%;
  top: 25%;
}
.circle-2 {
  width: 55rem;
  height: 55rem;
  border-radius: 50%;
  background: rgba(69, 122, 245, 0.2);
  filter: blur(300px);
  position: absolute;
  left: -6%;
  top: 17%;
}
.circle-3 {
  width: 75rem;
  position: absolute;
  right: -36%;
  top: -2%;
}

.hp-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
  padding: 5rem;
}
.hp-links a {
  font-size: 2rem;
  color: var(--white);
  font-family: "Lato";
  font-weight: 400;
}

.custom-h2 {
  font-size: 2.5rem !important;
  font-family: "Lato";
  font-weight: 400;
  line-height: 150%;
}

.custom-h3 {
  font-size: 3.5rem;
  font-family: "Lato";
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  padding-bottom: 0px;
}

/* Header  */

header {
  width: 100%;
  height: 7rem;
  padding: 5rem 0;
  z-index: 3;
  position: relative;
}
.header_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_cont img {
  width: 125px;
  height: auto;
}
.header_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5rem;
}
.header_right a {
  font-size: 1.6rem;
  font-family: "Roboto";
  font-weight: 400;
  color: var(--color-slate-400);
  transition: 0.35s;
  position: relative;
}
.header_right a svg {
  color: var(--color-slate-200);
  font-size: 1.45rem;
  margin: 0 0 0 0.5rem;
}
.header_right a:hover {
  color: var(--color-slate-300);
}
.header_right a.active {
  color: var(--white);
}

.dropdown-box {
  background-color: var(--color-slate-900);
  padding: 1.75rem;
  border-radius: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.5rem;
  position: absolute;
  width: max-content;
  transform: translate(-10%, 5%);
}
.dropdown-box a {
  color: var(--color-slate-200);
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
}

/* Hero Section  */
.hero-section {
  width: 100%;
  padding: 15rem 0 10rem 0;
  position: relative;
}

.main-home-circle {
  background: var(--color-slate-900);
  filter: blur(250px);
  width: 1336px;
  height: 1336px;
  position: absolute;
  top: -80%;
  left: -24%;
  z-index: 0;
  border-radius: 50%;
}
.hero-content {
  z-index: 1;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12rem;
}
.hero-cont-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}
.hero-cont-left span {
  font-size: 2rem;
  font-family: "Lato";
  font-weight: 400;
  color: var(--white);
  margin: 0;
}
.hero-cont-left h1 {
  font-size: 5rem;
  font-weight: 400;
  line-height: 125%;
}
.hero-cont-left p {
  font-size: 1.8rem;
  font-family: "Roboto";
  font-weight: 300;
  font-style: normal;
  line-height: 150%;
  color: var(--white);
  margin: 5rem 0 5.5rem 0;
}
.hero-cont-right svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Service Section  */
.service-section {
  width: 100%;
  padding: 10rem 0;
}
.service-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.service-content h2 {
  font-size: 3.8rem;
  font-family: "Lato";
  font-weight: 400;
  color: var(--color-light-primary);
  line-height: 150%;
  text-align: center;
  width: 80%;
}
.service-content p {
  font-size: 1.8rem;
  font-family: "Roboto";
  text-align: center;

  font-style: normal;
  line-height: 150%;
  color: var(--white);
  margin: 5rem 0 0 0;
}

.service-grid-cont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5rem;
  margin: 10rem 0 15rem 0;
  width: 100%;
  position: relative;
  z-index: 100;
}
.service-grid-box a {
  margin-top: 0 !important;
}
.service-grid-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2.5rem 3rem;
  border-radius: 1.25rem;
  background: var(--color-slate-900);
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
}
.circle-sm {
  transition: 0.35s;
}
.service-grid-box:hover .circle-sm {
  scale: 1.5;
}
.circle-sm-1 {
  background: rgba(0, 255, 170, 0.5);
  width: 171px;
  height: 171px;
  position: absolute;
  right: 14px;
  bottom: -25px;
  filter: blur(200px);
}
.circle-sm-2 {
  background: #9c42f5;
  width: 171px;
  height: 171px;
  position: absolute;
  right: 14px;
  bottom: -25px;
  filter: blur(200px);
}
.circle-sm-3 {
  background: #f43f5e;
  width: 171px;
  height: 171px;
  position: absolute;
  right: 0px;
  bottom: -10px;
  filter: blur(200px);
}
.circle-sm-4 {
  background: #4579f5;
  width: 171px;
  height: 171px;
  position: absolute;
  right: -11px;
  bottom: -25px;
  filter: blur(200px);
}
.service-grid-box img,
.service-grid-box svg {
  width: 75px;
  height: auto;
}
.service-grid-box h5 {
  font-size: 3rem;
  font-family: "Lato";
  font-weight: 400;
  line-height: 150%;
  background: linear-gradient(to bottom, #00ff88, #e8f0ff);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}
.service-grid-box.sgb-2 h5 {
  background: linear-gradient(to bottom, #9c42f5, #e8f0ff);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}
.service-grid-box.sgb-3 h5 {
  background: linear-gradient(to bottom, #f43f5e, #e8f0ff);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}
.service-grid-box.sgb-4 h5 {
  background: linear-gradient(to bottom, #4579f5, #e8f0ff);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}
.service-grid-box p {
  font-size: 1.8rem;
  font-family: "Lato";
  font-weight: 400;
  line-height: 150%;
  color: var(--white);
}
.service-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5px;
  background: linear-gradient(to right, #00ff88, #e8f0ff, #00ff88);
  border-radius: 0.75rem;
  margin-top: 1rem;
  cursor: pointer;
}
.service-grid-box.sgb-2 .service-btn {
  background: linear-gradient(to right, #9c42f5, #e8f0ff, #9c42f5);
}
.service-grid-box.sgb-2 .service-btn .inner-service-btn span {
  color: var(--color-primary-purple);
}
.service-grid-box.sgb-3 .service-btn {
  background: linear-gradient(to right, #f43f5e, #e8f0ff, #f43f5e);
}
.service-grid-box.sgb-3 .service-btn .inner-service-btn span {
  color: #ef4444;
}
.service-grid-box.sgb-4 .service-btn {
  background: linear-gradient(to right, #4579f5, #e8f0ff, #4579f5);
}
.service-grid-box.sgb-4 .service-btn .inner-service-btn span {
  color: var(--color-primary-blue);
}
.inner-service-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 3.5rem;
  border-radius: 0.75rem;
  backdrop-filter: blur(25px);
  background-color: var(--color-slate-900);
}
.inner-service-btn span {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 150%;
  color: var(--color-primary-green);
}

.service-graphic-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  text-align: center;
  flex-direction: column;
  position: relative;
}
.service-content a {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  color: var(--color-slate-400);
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 5rem;
  transition: 0.35s;
}
.service-content a:hover {
  color: var(--color-slate-100);
}
.service-content a:hover > svg {
  color: var(--color-slate-100);
  transform: translateX(7px);
}
.service-content a svg {
  transition: 0.35s;
  font-size: 2rem;
  color: var(--color-slate-400);
}
.service-graphic-text h3 {
  font-size: 3.25rem;
  font-family: "Lato";
  font-weight: 400;
  line-height: 150%;
  /* background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0)); */
  /* -webkit-background-clip: text; */
  color: var(--color-slate-200);
  display: inline-block;
  z-index: 2;
  text-align: center;
}
.service-graphic-text .rect-img {
  position: absolute;
  top: -15px;
  left: 0;
  z-index: 1;
  width: 100px;
}
.service-graphic-text .cir-img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 104px;
}

/* Benefits Section */
.benefits-section {
  width: 100%;
  padding: 10rem 0;
  background-color: var(
    --color-slate-950
  ) !important; /* Dark background color to match dark mode */
  overflow: hidden; /* Prevent content overflow */
}

.benefits-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  box-sizing: border-box; /* Ensure padding does not cause overflow */
}

.benefits-content h2 {
  padding-top: 10rem;
  font-size: 2.5rem;
}

.benefits-content p {
  font-size: 1.8rem;
  font-family: "Roboto";
  font-weight: 400;
  color: var(--color-slate-300); /* Softer light text color */
  line-height: 150%;
  margin-bottom: 5rem;
}

.benefits-grid-cont {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns for desktop */
  gap: 2rem;
  width: 100%;
  max-width: 1200px; /* Optional: Set a max width for desktop */
  margin: 0 auto; /* Center the grid container */
}

/* Benefits Section */
.benefits-grid-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; /* Distribute space between items */
  background: var(
    --color-slate-950
  ); /* Slightly lighter dark background for contrast */
  border-radius: 1.25rem;
  padding: 2.5rem 3rem;
  backdrop-filter: blur(10px);
  border: 2px solid transparent; /* Initial border style */
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease,
    background-color 0.3s ease;
  height: 100%; /* Ensure all boxes are the same height */
  min-height: 280px; /* Increase minimum height for better spacing */
  width: 100%; /* Full width to fill the container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.benefits-grid-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4); /* Darker shadow for hover effect */
  border-radius: 1.25rem; /* Ensures gradient border follows the same radius */
  border: 2px solid; /* Border width on hover */
  border-image-slice: 1; /* Ensures the gradient is displayed properly */
  border-image-source: linear-gradient(
    to right,
    var(--color-primary-green),
    var(--color-primary-purple)
  ); /* Gradient border */
  background-color: var(--color-slate-900); /* Darker background on hover */
}

.benefits-grid-box h5 {
  font-size: 2.5rem;
  font-family: "Lato";
  font-weight: 400;
  line-height: 150%;
  background: linear-gradient(
    to bottom,
    var(--color-primary-green),
    var(--color-primary-blue)
  );
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 1rem;
}

.benefits-grid-box p {
  font-size: 1.6rem;
  font-family: "Lato";
  font-weight: 400;
  line-height: 150%;
  color: var(--color-slate-200); /* Light text color for readability */
  flex-grow: 1; /* Ensure the paragraph takes up available space */
}

/* Swiper Navigation Button Adjustments for Mobile */
.swiper-button-prev,
.swiper-button-next {
  color: var(--color-primary-green);
  width: 40px;
  height: 40px;
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  z-index: 10;
}

.swiper-button-prev {
  left: -10px; /* Position on the left side */
}

.swiper-button-next {
  right: -10px; /* Position on the right side */
}

@media screen and (max-width: 768px) {
  .benefits-grid-cont {
    display: none; /* Hide grid on mobile */
  }

  .swiper-container {
    display: block; /* Show Swiper on mobile */
  }

  .swiper-slide {
    width: calc(
      100% - 4rem
    ); /* Ensure Swiper slide fits within screen with padding */
    box-sizing: border-box;
    padding: 0 2rem; /* Ensure padding within the slide */
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    display: none; /* Hide Swiper on larger screens */
  }

  .benefits-grid-cont {
    display: grid; /* Show grid on larger screens */
  }
}

.isMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px !important;
  padding-left: 40px !important;
  min-width: 100% !important;
  min-height: 300px !important;
}
.isMobile h5 {
  padding-bottom: 20px;
}

/* Team Section  */
.team-section {
  width: 100%;
  background-color: var(--color-slate-900);
  padding: 10rem 0;
  position: relative;
  z-index: 1;
}
.team-circle-1 {
  border-radius: 680px;
  background: rgba(69, 121, 245, 0.2);
  filter: blur(250px);
  width: 680px;
  height: 680px;
  position: absolute;
  right: -20%;
  top: -10%;
  z-index: revert-layer;
}
.team-circle-2 {
  border-radius: 680px;
  background: rgba(156, 66, 245, 0.2);
  filter: blur(250px);
  width: 680px;
  height: 680px;
  position: absolute;
  left: -20%;
  bottom: -10%;
  z-index: revert-layer;
}
.swiper-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 5rem 0;
  position: relative;
  z-index: 1000;
}

.swiper-head p {
  color: var(--white);
  font-family: "Lato";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 150%;
  width: 60%;
  text-align: flex-start;
}
.swiper-head h2 {
  font-size: 3.5rem;
  font-family: "Lato";
  font-weight: 400;
  line-height: 150%;
}
.sh-right {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3.5rem;
}
.sh-right svg {
  font-size: 2rem;
  color: var(--white);
  transition: 0.35s;
  cursor: pointer;
}
.sh-right .swiper-button-disabled svg {
  color: var(--white);
  cursor: auto;
}
.sh-right span:not(.swiper-button-disabled):hover svg {
  opacity: 0.85;
}
#home_page .swiper {
  overflow: visible !important;
}
.team-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  position: relative;
}
.team-card img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}
.team-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2.5rem 3rem;
  background: linear-gradient(
    180deg,
    rgba(15, 23, 42, 0) 0%,
    rgba(2, 6, 23, 0.8) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.team-bottom h5 {
  color: var(--color-slate-50);
  font-family: "Lato";
  font-size: 2rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.35rem;
  margin-bottom: 0;
}
.team-bottom h5 span {
  color: var(--color-slate-300);
  font-size: 1.35rem;
  font-weight: 400;
}
.tb-social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.tb-social a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--white);
}
.tb-social a svg {
  font-size: 1.85rem;
  color: var(--black);
}
/* Get In Touch  */
.getintouch-section {
  width: 100%;
  padding: 10rem 0;
}

.Marquee-Card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.5rem;
  margin-right: 3.5rem;
}
.Marquee-Card h3 {
  font-family: "Lato";
  font-size: 4.8rem;
  font-weight: 400;
  background: linear-gradient(180deg, #4579f5 0%, #e8f0ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Marquee-Card div {
  background: rgba(255, 255, 255, 0.5);
  height: 2px;
  width: 100px;
}

/* CASE STUDY SECTION  */
.casestudy-section {
  width: 100%;
  padding: 0 0 1rem 0;
  z-index: 1;
  position: relative;
}
.casestudy-section.casestudy-main-page {
  padding: 0px;
}
.case-circle1 {
  width: 650px;
  z-index: -1;
  position: absolute;
  left: -32%;
  top: -3%;
  transform: rotate(180deg);
}
.case-circle2 {
  width: 650px;
  position: absolute;
  right: -30%;
  top: 28%;
  z-index: -1;
}
.case-circle3 {
  width: 650px;
  position: absolute;
  left: -30%;
  bottom: 3%;
  transform: rotate(180deg);
  z-index: -1;
}
.casestudy-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8rem;
  width: 100%;
}
.casestudy-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2rem;
}
.casestudy-head h3 {
  font-family: "Lato";
  font-size: 3.6rem;
  font-weight: 400;
}
.casestudy-head a {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  color: var(--color-slate-400) !important;
  font-size: 1.8rem;
  font-weight: 400;
  transition: 0.35s;
}
.casestudy-head a:hover {
  color: var(--color-slate-100) !important;
}
.casestudy-head a:hover > svg {
  color: var(--color-slate-100);
  transform: translateX(7px);
}
.casestudy-head a svg {
  transition: 0.35s;
  font-size: 2rem;
  color: var(--color-slate-400);
}
.casestudy-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 8rem;
}
.cs-main-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8rem;
  width: 100%;
}
.cs-main-right {
  width: 100%;
  height: 40rem;
}
.cs-main-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}
.cs-main-left {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 3.5rem;
  flex-direction: column;
  width: 100%;
  height: max-content;
}
.cs-main-left img {
  width: 143px;
  height: auto;
  object-fit: cover;
}
.cs-main-left p {
  color: var(--color-slate-300);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 150%;
}
.ind-serv-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 1.5rem;
}
.ind-serv-box span {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--color-slate-500);
  font-size: 1.8rem;
  font-weight: 400;
}
.ind-serv-box span h6 {
  color: var(--color-slate-100);
  font-size: 1.8rem;
  font-weight: 700;
}
.cs-main-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6rem;
}
.cs-main-btn .service-btn {
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5px;
  background: linear-gradient(
    94deg,
    #0fa -11.18%,
    #4579f5 47.98%,
    #9c42f5 99.4%
  );
  border-radius: 0.75rem;
}
.cs-main-btn .inner-service-btn span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--color-slate-400);
  font-size: 1.8rem;
  font-weight: 400;
}
.cs-main-btn .inner-service-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 3.5rem;
  border-radius: 0.75rem;
  background: #020617;
  backdrop-filter: blur(25px);
}
.cs-main-btn h5 {
  color: var(--color-slate-400);
  font-size: 1.8rem;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  transition: 0.35s;
  margin: 0;
  cursor: pointer;
}
.cs-main-btn h5:hover {
  color: var(--color-slate-100);
}
.cs-main-btn h5:hover > svg {
  color: var(--color-slate-100);
}
.cs-main-btn h5 svg {
  color: var(--color-slate-400);
  font-size: 2rem;
  transition: 0.35s;
}
.cs-main-grid.middle-main-grid {
  padding: 3.5rem;
  border-radius: 1.5rem;
  background-color: var(--color-slate-900);
}

/* BLOG SECTION  */
.blog-section {
  background-color: var(--color-slate-900);
  padding: 10rem 0;
  width: 100%;
  position: relative;
  z-index: 1;
}
.blog-section-corners {
  background-color: var(--color-slate-900);
  padding: 10rem 0;
  width: 100%;
  position: relative;
  z-index: 1;
  border-radius: 1rem;
}

.blogcircle-1 {
  width: 104px;
  height: 104px;
  background-color: rgba(0, 255, 170, 0.3);
  border-radius: 50%;
  position: absolute;
  right: -3%;
  top: 35%;
}
.blogcircle-2 {
  background: rgba(69, 121, 245, 0.2);
  filter: blur(250px);
  width: 680px;
  height: 680px;
  border-radius: 50%;
  position: absolute;
  right: -20%;
  top: -20%;
  z-index: -1;
}
.blog-cont {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8rem;
}
.blog-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  width: 100%;
  position: relative;
  z-index: 100;
}
.blog-grid-left {
  width: 100%;
}
.blog-grid-left .blog-main-cont img {
  width: 100%;
  height: 395px;
  object-fit: cover;
  border-radius: 1rem;
}
.blog-main-cont {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-slate-800);
  border-radius: 1rem;
  cursor: pointer;
}
.blog-grid-right.bgr-home .blog-main-cont img {
  width: 100%;
  height: 190px !important;
  object-fit: cover;
  border-radius: 1rem;
}
.blogs-grid .blog-main-cont.bm-r-cont img {
  height: 290px !important;
  object-fit: cover;
  border-radius: 1rem;
}
.blog-main-cont div {
  border-radius: 0 0 1rem 1rem;
  padding: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 9rem;
  width: 100%;
  height: 100%;
}
.blog-main-cont div h4 {
  color: var(--white);
  font-family: "Lato";
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 150%;
}
.blog-main-cont div a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--color-slate-400);
  transition: 0.35s;
}
.blog-main-cont:hover a {
  color: var(--color-slate-100);
}
.blog-main-cont:hover a svg {
  color: var(--color-slate-100);
}
.blog-main-cont:hover div a > svg {
  transform: translateX(7px);
}
.blog-main-cont div a svg {
  transition: 0.35s;
  font-size: 2rem;
  color: var(--color-slate-400);
}
.blog-grid-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  width: 100%;
}
.blog-main-cont.bm-r-cont img {
  height: 290px !important;
}
.blog-main-cont.bm-r-cont div {
  padding: 1.75rem;
  gap: 2rem;
}
.blog-main-cont.bm-r-cont div h4 {
  text-overflow: ellipsis;
  display: -webkit-box;
  font-family: "Roboto";
  font-size: 1.6rem;
  line-height: 125%;
  width: 267px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.blog-main-cont.bm-r-cont div a {
  font-size: 1.4rem;
}
.blog-main-cont.bm-r-cont div a svg {
  font-size: 1.5rem;
}

/* FAQ SECTION  */
.faq-section {
  width: 100%;
  padding: 15rem 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.faqrectangle {
  background: rgba(156, 66, 245, 0.3);
  width: 100.58px;
  height: 100.58px;
  transform: rotate(15deg);
  position: absolute;
  top: -7.5%;
  left: 9%;
  z-index: -1;
}
.faq-circle {
  background: rgba(156, 66, 245, 0.3);
  filter: blur(250px);
  position: absolute;
  bottom: 20%;
  right: -20%;
  z-index: -1;
  width: 680px;
  height: 680px;
}
.faq-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 0;
}
.faq-content h3 {
  font-size: 4.8rem;
  font-family: "Lato";
  font-weight: 400;
}
.faq-accordian-box {
  width: 100%;
  padding-top: 5rem;
  max-width: 900px;
  margin: auto;
}
.faq-accordian-box .accordion-item:hover h5 {
  color: var(--color-slate-300);
}
.faq-accordian-box .accordion-item {
  padding: 2.5rem;
  border-radius: 1rem;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
  /* background: linear-gradient(
    99deg,
    rgba(0, 255, 170, 0.15) 0%,
    rgba(69, 121, 245, 0.15) 50.53%,
    rgba(156, 66, 245, 0.15) 94.44%
  ); */
  background-color: var(--color-slate-800);
  border: unset;
  margin-bottom: 2rem;
  transition: 0.35s;
}
.faq-accordian-box .accordion-header .accordion-button::after {
  display: none;
}
.faq-accordian-box .accordion-header {
  background-color: transparent;
  border: unset;
  padding: 0;
}
.faq-accordian-box .accordion-header button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.faq-accordian-box .accordion-header svg {
  color: var(--color-slate-200);
  font-size: 2rem;
  transform: rotate(180deg);
  transition: 0.35s;
}
.faq-accordian-box .accordion-header .accordion-button.collapsed svg {
  transform: rotate(0);
}
.faq-accordian-box .accordion-header .accordion-button:not(.collapsed) {
  background-color: transparent;
  border: unset;
  padding: 0;
  box-shadow: none;
}
.faq-accordian-box .accordion-header .accordion-button {
  background-color: transparent;
  border: unset;
  padding: 0;
  box-shadow: none;
}
.faq-accordian-box .accordion-button:focus {
  outline: none;
}
.faq-accordian-box .accordion-header h5 {
  font-family: "Lato";
  font-size: 2rem;
  font-weight: 600;
  line-height: 150%;
  transition: 0.35s;
  color: var(--color-slate-200);
}

.faq-accordian-box .accordion-header .accordion-button.collapsed h5 {
  color: var(--color-slate-400);
}
.faq-accordian-box .accordion-body {
  padding: 1.5rem 1.5rem 0 0;
  color: var(--color-slate-300);
  font-family: "Lato";
  font-size: 1.65rem;
  font-weight: 400;
  line-height: 170%;
}

/* BOOKING SECTION */
.booking-section {
  width: 100%;
  background-color: var(--color-slate-900);
  position: relative;
  overflow: hidden;
}

.book-a-call-container {
  background-color: var(--color-slate-900);
  padding: 5rem;
  border-radius: 1rem;
  position: fixed;
  width: 600px;
  max-width: 90%;
  min-height: 300px;
  left: 50%;
  color: var(--color-light-primary);
  transform: translate(-50%, -50%); /* Center horizontally and vertically */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001; /* Ensure it's above other content */
  top: 50%; /* Vertically center */
  transition: all 0.3s ease; /* Smooth transition for opening */
}
.book-a-call-container a {
  color: var(--color-primary-purple);
}

.book-a-call-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center; /* Center items both vertically and horizontally */
  z-index: 1000;
}

.book-a-call-container .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.7rem;
  cursor: pointer;
  color: var(--color-slate-200);
}

.book-a-call-container h3 {
  color: var(--white);
  font-family: "Lato";
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.book-a-call-container form {
  display: flex;
  flex-direction: column;
}

.book-a-call-container form input,
.book-a-call-container form textarea {
  border: 1px solid transparent;
  border-radius: 1rem;
  background-color: var(--color-slate-800);
  padding: 1.75rem 2rem;
  color: var(--color-slate-500);
  font-size: 1.75rem;
  font-weight: 400;
  font-family: "Lato";
  width: 100%;
  margin-bottom: 1.5rem;
  transition: 0.35s;
}

.book-a-call-container form input:focus,
.book-a-call-container form textarea:focus {
  border: 1px solid var(--color-slate-400);
  outline: none;
}

.book-a-call-container form input::placeholder,
.book-a-call-container form textarea::placeholder {
  color: var(--color-slate-500);
}

.book-a-call-container form input.error,
.book-a-call-container form textarea.error {
  border-color: #e84e3d; /* Error red color */
}

.book-a-call-container form .error-text {
  color: #e84e3d; /* Error red color */
  font-size: 0.875rem;
}

.book-a-call-container form .gradient-btn {
  background-image: linear-gradient(to bottom right, #00ffaa, #4579f5, #9c42f5);
  border: none;
  padding: 1.5rem;
  color: var(--white);
  font-size: 1.75rem;
  border-radius: 1rem;
  cursor: pointer;
}

.book-a-call-container form .gradient-btn.full-width {
  width: 100% !important;
}

.book-a-call-container form .gradient-btn:hover {
  opacity: 0.9;
}

.book-a-call-container .submission-status {
  margin-top: 1rem;
  font-size: 1.5rem;
  color: var(--color-primary-green);
  text-align: center;
}

.booking-cont {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
}

.booking-img {
  height: 100%;
  width: 65%;
}

.booking-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.booking-cont-chatPdf {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
}

.booking-img-chatPdf {
  height: 100%;
  width: 60%;
}
.booking-img-chatPdf img {
  width: 90%;
  height: 100%;
  object-fit: fit;
}

@media (max-width: 768px) {
  .book-a-call-container {
    padding: 2rem;
    width: 90%;
    height: auto;
    top: 50%; /* Center vertically */
    transform: translate(
      -50%,
      -50%
    ); /* Center both horizontally and vertically */
    overflow-y: auto;
  }

  .book-a-call-container h3 {
    font-size: 2rem;
  }

  .book-a-call-container form input,
  .book-a-call-container form textarea {
    padding: 1rem;
    font-size: 1.4rem;
  }

  .book-a-call-container form .gradient-btn {
    font-size: 1.5rem;
  }
}

/* CHATPDF LANDING SECTION */
.chatpdf-section {
  padding: 15rem 0 0 0;
}

.chatpdf-container {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  /* background-color: var(--color-slate-900) !important; */
  padding: 2rem;
  /* border-radius: 1rem; */
  /* position: fixed; */
  /* width: 600px; */
  /* max-width: 90%; */

  /* min-height: 300px; */

  color: var(--color-light-primary);

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  /* transition: all 0.3s ease; */
}

.contact-grid-chatpdf {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
}

.cg-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
  padding: 3.5rem;
  background-color: var(--color-slate-900);
  border-radius: 1rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cg-left h4 {
  color: var(--white);
  font-family: "Lato";
  font-size: 2.5rem;
  font-weight: 700;
}

.cg-left button {
  width: 100%;
}

.cg-left-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
  min-height: 350px;
}

.cg-left-form input,
.cg-left-form textarea {
  border: 1px solid transparent;
  border-radius: 1rem;
  background-color: var(--color-slate-800);
  padding: 1.75rem 2rem;
  color: var(--color-slate-500);
  font-size: 1.75rem;
  font-weight: 400;
  font-family: "Lato";
  width: 100%;
  transition: 0.35s;
}

.cg-left-form input:focus,
.cg-left-form textarea:focus {
  border: 1px solid var(--color-slate-400);
}

.cg-left-form input::placeholder,
.cg-left-form textarea::placeholder {
  color: var(--color-slate-500);
  font-size: 1.75rem;
  font-weight: 400;
  font-family: "Lato";
}

.cg-left-form input:focus,
.cg-left-form textarea:focus {
  outline: unset;
  box-shadow: unset;
}

.cg-right {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5rem;
}

.cg-right img {
  width: 100% !important;
  height: auto !important;
  border-radius: 1rem;
  object-fit: contain;
  margin: 0 !important;
}

.chatpdf-info-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}

.chatpdf-feature {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  color: var(--color-slate-300);
  font-size: 1.8rem;
  font-weight: 400;
  font-family: "Lato";
}

.chatpdf-feature span {
  display: flex;
  flex-direction: column;
}

.chatpdf-feature h6 {
  color: var(--color-slate-500);
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

.success-message {
  text-align: center;
  font-size: 2.8rem;
  color: var(--color-slate-300);
  padding: 2rem;
}

.check-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #00ff88; /* Green background */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1.5rem auto; /* Center the circle and add bottom margin */
}

.green-check {
  color: white; /* White check icon inside the circle */
  width: 35px;
  height: 35px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
  }

  .cg-left,
  .cg-right {
    padding: 2rem;
  }

  /* .cg-left-form {
    padding: 0px !important;
    display: flex;
    justify-content: center;
  } */

  .chatpdf-container {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    background-color: var(--color-slate-900) !important;
    padding: 2.5rem;
    /* border: 0.15rem solid var(--color-primary-purple); */
    box-shadow: none;
  }

  .chatpdfImg {
    max-width: 425px;
  }
}

/* Chat Button */

.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--color-primary-green);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.chat-button:hover {
  background-color: var(--color-primary-blue);
}

.chat-icon {
  color: var(--white);
  font-size: 1.8rem;
}

/* Chatbot Styles */
/* .chatbot-container {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 350px;
  max-width: 100%;
  height: 500px;
  background-color: var(--color-slate-900);
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.chatbot-header {
  background-color: var(--color-slate-800);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  font-size: 1.8rem;
  border-bottom: 1px solid var(--color-slate-700);
}

.chatbot-messages {
  flex: 1; 
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chatbot-message {
  padding: 0.75rem;
  border-radius: 0.5rem;
  max-width: 80%;
}

.chatbot-message.user {
  align-self: flex-end;
  background-color: var(--color-primary-blue);
  color: var(--white);
}

.chatbot-message.bot {
  align-self: flex-start;
  background-color: var(--color-slate-700);
  color: var(--white);
}

.chatbot-input-container {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-top: 1px solid var(--color-slate-700);
}

.chatbot-input-container input {
  flex: 1;
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--color-slate-800);
  color: var(--white);
  margin-right: 0.5rem;
}

.chatbot-input-container button {
  background-color: var(--color-primary-green);
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  color: var(--white);
  cursor: pointer;
}

.chatbot-input-container button:hover {
  background-color: var(--color-primary-blue);
}

.close-icon {
  font-size: 1.8rem; 
  cursor: pointer;
} */

/* Newletter Form */

.newsletter-section {
  width: 100%;
  padding: 25rem 0;
}

.newsletter-h4 h4 {
  color: var(--white);
  font-family: "Lato";
  font-size: 2rem;
  font-weight: 400;
}

.newsletterform-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5rem;
}

.newsletter-form h2 {
  font-size: 3.5rem;
  font-family: "Lato";
  font-weight: 400;
  color: var(--white);
  line-height: 150%;
  text-align: center;
  width: 80%;
}

.newsletter-form input,
.newsletter-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.newsletter-form button {
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.newsletter-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.newsletter-title {
  font-size: 2rem;
  font-weight: 600;
  color: var(--white);
  margin-top: 20px;
  text-align: center;
}

/* Loader Styles */
.newsletter-form .loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

/* Success Message Styles */
.success-message {
  color: green;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}

/* Error Message Styles */
.newsletter-form .error {
  color: red;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 10px;
}

/* Privacy Policy Styles */
.newsletter-form .privacy-policy {
  margin-top: 20px;
  font-size: 14px;
  color: var(--color-slate-300);
  text-align: center;
}

.newsletter-form .privacy-policy a {
  color: var(--color-slate-100);
  text-decoration: underline;
}

.privacy-policy {
  margin-top: 0px;
  font-size: 1rem;
  color: var(--white);
  text-align: center;
}

.privacy-policy-link {
  color: var(--white);
  text-decoration: underline;
  cursor: pointer;
}

/* end of newsletter form */

.contact-info-box h5 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-info-box h5 span {
  margin-right: 10px;
}

/* Git Section */
.git-box {
  border-radius: 1.5rem;
  background: var(--color-slate-800);
  backdrop-filter: blur(5px);
  display: flex;
  width: 100%;
  max-width: 550px;
  margin: auto;
  padding: 3.5rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5rem;
  position: relative;
  transform: translate(-50%, 0);
  z-index: 1;
}
.git-circle-1 {
  position: absolute;
  left: -86px;
  top: -95px;
  background: var(--color-primary-blue);
  filter: blur(150px);
  width: 171px;
  height: 171px;
  z-index: 0;
}
.git-circle-2 {
  position: absolute;
  right: -50%;
  bottom: -85px;
  background: rgba(69, 121, 245, 0.2);
  filter: blur(250px);
  width: 349px;
  height: 349px;
}
.git-b-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}
.git-b-top h3 {
  font-family: "Lato";
  font-size: 3.5rem;
  font-weight: 400;
}
.git-b-top p {
  font-family: "Lato";
  font-size: 1.8rem;
  font-weight: 300;
  color: var(--white);
}
.gitsvg-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 3rem;
}
.gitsvg-box div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
}
.gitsvg-box div img {
  width: 40px;
  height: auto;
  object-fit: cover;
}
.gitsvg-box div span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  color: var(--color-slate-100);
  font-family: "Lato";
  font-size: 1.8rem;
  font-weight: 600;
}
.gitsvg-box div span h6 {
  color: var(--color-slate-300);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 150%;
}

/* FOOTER SECTION  */
footer {
  width: 100%;
  padding: 5rem 0;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2rem;
}
.footer-content img {
  width: 125px;
  height: auto;
  object-fit: contain;
}

.footer-content h5,
.footer-content img {
  margin: 0;
  padding: 0;
}
.footer-content h5 {
  color: var(--color-slate-300);
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0;
}
footer h6 {
  display: none;
}
.footer-content .tb-social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
}
.footer-content .tb-social div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--white);
}
.footer-content .tb-social div svg {
  font-size: 1.85rem;
  color: var(--black);
}
.tb-social-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.tb-social-box .tbs-social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--white);
}
.tb-social-box .tbs-social-icon svg {
  font-size: 1.85rem;
  color: var(--black);
}

/* ABOUT PAGE  */
.about-section {
  width: 100%;
  padding: 15rem 0;
  position: relative;
  z-index: 1;
}
.about-circle-2 {
  width: 703px;
  height: 703px;
  background: rgba(156, 66, 245, 0.5);
  filter: blur(250px);
  border-radius: 50%;
  position: absolute;
  top: 35%;
  right: -20%;
  z-index: 0;
}
.about-circle-3 {
  width: 75rem;
  position: absolute;
  left: -36%;
  bottom: -5%;
  z-index: 0;
  transform: rotate(180deg);
}
.main-about-circle1 {
  background: var(--color-slate-900);
  filter: blur(250px);
  width: 1336px;
  height: 1336px;
  position: absolute;
  top: -25%;
  left: -24%;
  z-index: 1;
  border-radius: 50%;
}
.about-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8rem;
  width: 100%;
  z-index: 1;
  position: relative;
}
.about-c-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
}
.about-c-top h4 {
  color: var(--white);
  font-family: "Lato";
  font-size: 2rem;
  font-weight: 400;
}
.about-c-top h1 {
  text-align: center;
  font-size: 5.5rem;
  font-weight: 400;
  line-height: 125%;
}
.about-c-top p {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 150%;
  width: 65%;
  color: var(--white);
  margin-top: 3rem;
}
.about-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3rem;
}
.about-grid-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 3rem;
  border-radius: 1rem;
  gap: 2rem;
  background: var(--color-slate-900);
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
  min-height: 250px;
}
.ag-circle-1 {
  background: var(--color-primary-green);
  filter: blur(200px);
  position: absolute;
  right: -52.333px;
  bottom: -132px;
  width: 131px;
  height: 131px;
  z-index: -1;
}
.ag-circle-2 {
  background: var(--color-primary-blue);
  filter: blur(200px);
  position: absolute;
  right: -52.333px;
  bottom: -132px;
  width: 131px;
  height: 131px;
  z-index: -1;
}
.ag-circle-3 {
  background: var(--color-primary-purple);
  filter: blur(200px);
  position: absolute;
  right: -52.333px;
  bottom: -132px;
  width: 131px;
  height: 131px;
  z-index: -1;
}
.about-grid-content h5 {
  background: linear-gradient(180deg, #0fa 0%, #e8f0ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Lato";
  font-size: 2.4rem;
  font-weight: 400;
}
.about-grid-content.agc-2 h5 {
  background: linear-gradient(185deg, #4579f5 23.14%, #e8f0ff 96.02%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-grid-content.agc-3 h5 {
  background: linear-gradient(180deg, #9c42f5 0%, #e8f0ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-grid-content p {
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 150%;
}
.about-content.service-content img {
  margin: 0 auto 8rem auto;
}
.about-content img {
  width: 1100px;
  height: auto;
  margin: 8rem auto;
  z-index: 1;
}
.about-flex {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8rem;
  margin: 0 auto;
  z-index: 1;
}
.about-flex-box {
  display: grid;
  grid-template-columns: 1fr 1.35fr;
  grid-gap: 5rem;
  position: relative;
}
.about-circle1 {
  width: 104px;
  height: 104px;
  background-color: rgba(0, 255, 170, 0.3);
  position: absolute;
  left: -36px;
  top: 5%;
  border-radius: 50%;
  z-index: 0;
}
.about-circle2 {
  width: 104px;
  height: 104px;
  background-color: rgba(156, 66, 245, 0.3);
  position: absolute;
  left: -36px;
  top: -4%;
  border-radius: 50%;
  z-index: 0;
}
.about-circle3 {
  width: 104px;
  height: 104px;
  background-color: rgba(69, 121, 245, 0.4);
  position: absolute;
  left: -36px;
  top: -4%;
  border-radius: 50%;
  z-index: 0;
}
.about-flex-box h3 {
  color: var(--color-slate-100);
  font-family: "Lato";
  font-size: 2.5rem;
  font-weight: 600;
  z-index: 1;

  display: flex;
  align-items: center;
}
.about-flex-box p {
  color: var(--color-slate-400);
  font-family: "Lato";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 150%;
}

.about-flex.service-flex {
  gap: 10rem;
}
.about-flex.service-flex .about-circle1 {
  top: -55%;
}
.about-flex.service-flex .about-circle2 {
  top: -40%;
}
.about-flex.service-flex .about-circle3 {
  top: -40%;
}

/* BLOG PAGE  */
.main-blog-section {
  width: 100%;
  padding: 15rem 0 10rem 0;
  position: relative;
}
.main-blog-circle {
  background: var(--color-slate-900);
  filter: blur(250px);
  width: 1336px;
  height: 1336px;
  position: absolute;
  top: -24%;
  left: -24%;
  z-index: 0;
  border-radius: 50%;
}
.blog-page-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5rem;
  z-index: 1;
  border-radius: 1.5rem;
  position: relative;
}
.blog-page-cont img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 1.5rem;
}
.blog-page-cont a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  color: var(--color-slate-400);
  font-size: 1.6rem;
  font-weight: 400;
}
.blog-page-cont a svg {
  color: var(--color-slate-400);
  font-size: 1.75rem;
}
.blog-page-cont h1 {
  color: var(--white);
  font-family: "Lato";
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 140%;
}
.blog-page-cont h2 {
  color: var(--white);
  font-family: "Lato";
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 140%;
}
.blog-page-cont h3 {
  color: var(--white);
  font-family: "Lato";
  font-size: 3.8rem;
  font-weight: 400;
  line-height: 140%;
}
.blog-page-cont h5 {
  color: var(--color-slate-300);
  font-family: "Lato";
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 150%;
}
.blog-page-cont p {
  color: var(--color-slate-300);
  font-family: "Lato";
  font-size: 2rem;
  font-weight: 300;
  line-height: 150%;
}

/* BLOGS PAGE  */

.about-section.blogs-main-section {
  padding: 15rem 0 0rem 0;
}
.blogs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  width: 100%;
  grid-template-rows: auto;
}
.blogs-grid .blog-main-cont img {
  width: 100%;
  height: auto;
  margin: 0;
}
.blogs-grid .blog-main-cont div h4 {
  font-size: 2.2rem;
  line-height: 150%;
  display: block;
  width: 100%;
}

/* SERVICE PAGE  */
.technologies-section {
  width: 100%;
  padding: 10rem 0 10rem 0;
}
.technologies-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 5rem;
}
.technologies-content h3 {
  font-size: 3.5rem;
  font-family: "Lato";
  font-weight: 400;
  line-height: 150%;
}
.technologies-tabs .nav.nav-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  border: 0;
}
.technologies-tabs .nav.nav-tabs .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: unset;
  border-radius: 0.75rem;
  padding: 1.25rem 3.5rem;
  color: var(--color-slate-400);
  font-size: 1.8rem;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  border: unset;
}
.technologies-tabs .nav.nav-tabs .nav-link:hover {
  color: var(--white);
}
.technologies-tabs .nav.nav-tabs .nav-link.active {
  background-image: linear-gradient(to bottom right, #00ff88, #4579f5, #9c42f5);
  color: var(--white);
}
.technologies-tabContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5rem;
  margin-top: 8rem;
}
.technologies-tabContent p {
  color: var(--white);
  font-family: "Lato";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 150%;
  width: 60%;
  text-align: center;
}
.technologies-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
}
.tb-box-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 2rem 0;
  background-color: var(--color-slate-800);
  border-radius: 1rem;
  height: 225px;
  width: 100%;
  max-width: 225px;
  transition: 0.35s;
  cursor: pointer;
}
.tb-box-img:hover {
  background-color: var(--color-slate-700);
}
.tb-box-img img {
  width: auto;
  height: auto;
  object-fit: contain;
}
.tb-box-img svg {
  font-size: 10rem;
  color: var(--white);
}
.tb-box-img h4 {
  color: var(--white);
  font-family: "Lato";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 150%;
}

/* CASE STUDY PAGE  */
.casestudy-section.cs-section-2 {
  padding: 0 0 5rem 0;
}
.casestudy-cont.main-cs-page {
  z-index: 3;
  position: relative;
  padding-top: 10rem;
}
.main-cs-circle {
  width: 75rem;
  height: 75rem;
  border-radius: 50%;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: absolute;
  left: -40%;
  bottom: 1%;
  z-index: 0;
}
.casestudy-cont.main-cs-page .cs-main-grid {
  display: flex;
  flex-direction: column;
}
.casestudy-cont.main-cs-page .cs-main-grid .cs-main-right img {
  height: 441px;
  border-radius: 1.5rem;
}
.casestudy-cont.main-cs-page .cs-main-btn {
  justify-content: space-between;
  width: 100%;
}
.casestudy-cont.main-cs-page .cs-main-btn h4 {
  color: var(--white);
  font-family: "Lato";
  font-size: 2.5rem;
  font-weight: 700;
}
.steps-to-follow-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0;
  padding: 10rem 0 5rem 0;
  width: 100%;
}
.steps-to-follow-box img {
  width: 552px;
  height: auto;
  object-fit: contain;
}
.main-steps-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}
.ms-box-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  z-index: 3;
  padding: 2rem 2rem 8rem 2rem;
}
.msbc-1 {
  padding: 2rem 2rem 8rem 2rem;
  position: relative;
}
.msbc-2 {
  padding: 2rem 2rem 8rem 2rem;
  position: relative;
}
.msbc-3 {
  padding: 8rem 2rem 2rem 2rem;
  position: relative;
}
.msbc-4 {
  padding: 8rem 2rem 2rem 2rem;
  position: relative;
}
.msbc-1 .hor-line {
  width: 100%;
  height: 1px;
  background-color: var(--color-slate-500);
  position: absolute;
  top: 0;
  left: 0;
}
.msbc-1 .ver-line {
  width: 1px;
  height: 100%;
  background-color: var(--color-slate-500);
  position: absolute;
  top: 0;
  right: 0;
}
.msbc-2 .hor-line {
  width: 100%;
  height: 1px;
  background-color: var(--color-slate-500);
  position: absolute;
  top: 0;
  right: 0;
}
.msbc-2 .ver-line {
  width: 1px;
  height: 100%;
  background-color: var(--color-slate-500);
  position: absolute;
  top: 0;
  left: 0;
}

.msbc-3 .hor-line {
  width: 100%;
  height: 1px;
  background-color: var(--color-slate-500);
  position: absolute;
  bottom: 0;
  right: 0;
}
.msbc-3 .ver-line {
  width: 1px;
  height: 100%;
  background-color: var(--color-slate-500);
  position: absolute;
  top: 0;
  right: 0;
}

.msbc-4 .hor-line {
  width: 100%;
  height: 1px;
  background-color: var(--color-slate-500);
  position: absolute;
  bottom: 0;
  left: 0;
}
.msbc-4 .ver-line {
  width: 1px;
  height: 100%;
  background-color: var(--color-slate-500);
  position: absolute;
  top: 0;
  left: 0;
}

/* .ms-box-content.msbc-2 {
  border-left: 1px solid var(--color-slate-500);
  border-right: 0;
}
.ms-box-content.msbc-3 {
  border-top: 0;
  padding: 8rem 2rem 2rem 2rem;
  border-bottom: 1px solid var(--color-slate-500);
}
.ms-box-content.msbc-2.msbc-4 {
  border-top: 0;
  padding: 8rem 2rem 2rem 2rem;
  border-bottom: 1px solid var(--color-slate-500);
} */
.ms-box-content h4 {
  font-family: "Lato";
  font-size: 2rem;
  font-weight: 600;
  line-height: 150%;
}
.ms-box-content p {
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 150%;
}

/* Testimonial */
.testi-circle {
  background: var(--color-slate-500);
  filter: blur(200px);
  position: absolute;
  right: -52.333px;
  bottom: 0%;
  width: 131px;
  height: 131px;
  z-index: 1;
}

.testimonial-section {
  width: 100%;
  padding: 15rem 0;
  position: relative;
}

.testimonial-cs-box,
.testimonial-cs-box-component {
  padding: 3.5rem;
  background-color: #0f172a;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  width: 865px; /* Use max-width if needed to make it responsive */
  margin: 0 auto;
  position: relative;
  overflow: hidden; /* Hide overflow content if needed */
}

.testimonial-cs-box-component {
  justify-content: center;
  width: auto; /* Allows flexibility for width */
  height: 450px; /* Set a fixed height if required */
  padding: auto; /* This might need adjustment or removal */
}

.testimonial-cs-box p,
.testimonial-cs-box-component p {
  color: var(--white);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 150%;
  position: relative;
  z-index: 1;
}

.testimonial-cs-box p img,
.testimonial-cs-box-component p img {
  position: absolute;
  z-index: -2;
  top: -45%; /* Adjusted to maintain consistency */
  left: 0%;
  bottom: -50px; /* Specific to component, but aligned similarly */
}

.test-cs-profile,
.testimonial-cs-box div,
.testimonial-cs-box-component div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.test-cs-profile img {
  width: 200px;
  height: auto;
  object-fit: cover;
}

.testimonial-cs-box div img,
.testimonial-cs-box-component div img {
  width: 75px;
  height: auto;
  object-fit: contain;
  border-radius: 50%;
  border-image-source: 2px solid
    linear-gradient(180deg, #00ff88 0%, #4579f5 53.5%, #9c42f5 100%);
}

.testimonial-cs-box div h6,
.testimonial-cs-box-component div h6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.25rem;
  color: var(--white);
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0;
}

.testimonial-cs-box div h6 span,
.testimonial-cs-box-component div h6 span {
  color: var(--color-slate-400);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 150%;
}

/* CONTACT PAGE  */
.about-section.contact-section {
  padding: 15rem 0 0 0;
}
.contact-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
}
.contact-circle {
  width: 303px;
  height: 303px;
  background: var(--color-slate-900) !important;
  filter: blur(200px);
  border-radius: 50%;
  position: absolute;
  top: 65%;
  left: -6%;
  z-index: -1;
}
.cg-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
  padding: 3.5rem;
  background-color: var(--color-slate-900);
  border-radius: 1rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.cg-left h4 {
  color: var(--white);
  font-family: "Lato";
  font-size: 2.5rem;
  font-weight: 700;
}
.cg-left button {
  width: 100%;
}
.cg-left-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
  min-height: 350px;
}
.cg-left-form input,
.cg-left-form textarea {
  border: 1px solid transparent;
  border-radius: 1rem;
  background-color: var(--color-slate-800);
  padding: 1.75rem 2rem;
  color: var(--color-slate-500);
  font-size: 1.75rem;
  font-weight: 400;
  font-family: "Lato";
  width: 100%;
  transition: 0.35s;
}
.cg-left-form input:focus,
.cg-left-form textarea:focus {
  border: 1px solid var(--color-slate-400);
}
.cg-left-form input::placeholder,
.cg-left-form textarea::placeholder {
  color: var(--color-slate-500);
  font-size: 1.75rem;
  font-weight: 400;
  font-family: "Lato";
}
.cg-left-form input:focus,
.cg-left-form textarea:focus {
  outline: unset;
  box-shadow: unset;
}
.cg-right {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5rem;
}
.cg-right img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  object-fit: contain;
  margin: 0 !important;
}
.contact-info-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}
.contact-info-box h5 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  color: var(--color-slate-300);
  font-size: 1.8rem;
  font-weight: 400;
  font-family: "Lato";
}
.contact-info-box h5 span {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--color-slate-800);
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-info-box h5 span svg {
  font-size: 1.95rem;
  color: var(--color-slate-300);
}

/* MOBILE HEADER  */
.hr-mob-icon {
  display: none;
  justify-content: flex-end;
  align-items: center;
}
.hr-mob-icon svg {
  font-size: 3rem;
  color: var(--white);
}
.mob-circle-1 {
  position: absolute;
  bottom: 4%;
  left: -35%;
  width: 30rem;
  height: 30rem;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}
.mob-circle-2 {
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  background: rgba(155, 66, 245, 0.2);
  filter: blur(200px);
  position: absolute;
  right: -20%;
  top: 25%;
}
.mobile-menu {
  padding: 2.5rem;
  background-color: var(--color-slate-900);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5rem;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.mobile-menu-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.mobile-menu-top img {
  width: 50px;
  height: auto;
}
.mobile-menu-top svg {
  font-size: 3rem;
  color: var(--white);
}
.mobile-menu-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 3rem;
  width: 100%;
  position: relative;
  z-index: 1000;
}
.mobile-menu-bottom a {
  font-size: 2.2rem;
  color: var(--white);
  font-weight: 500;
  margin: 0;
}

/* RESPONSIVENESS  */
@media only screen and (max-width: 1350px) {
  .box {
    padding: 0 5rem;
  }
  .booking-cont {
    height: unset;
  }
}
@media only screen and (max-width: 1250px) {
  .hero-content {
    grid-template-columns: 1.35fr 1fr;
  }
  .hero-cont-left h1 {
    font-size: 4.5rem;
  }
  .hero-cont-left p {
    margin: 2rem 0 3rem 0;
  }
  .cs-main-grid {
    grid-gap: 4rem;
  }
  .cs-main-left {
    gap: 2rem;
  }
}
@media only screen and (max-width: 1150px) {
  .booking-section {
    padding: 7.5rem 2.5rem;
  }
  .booking-img {
    display: none;
  }
  .git-box {
    transform: unset;
    padding: 3rem 2rem;
    gap: 3rem;
    border-radius: 1rem;
  }
  .contact-grid {
    grid-template-columns: 1.35fr 1fr;
  }
  .contact-grid-chatpdf {
    grid-template-columns: 1.35fr 1fr;
  }
}
@media only screen and (max-width: 1100px) {
  .hero-cont-left h1 {
    font-size: 4rem;
  }
  .hero-content {
    grid-gap: 5rem;
  }
  .service-content h2 {
    width: 90%;
  }
  .service-graphic-text {
    width: 85%;
  }
  .blog-grid {
    grid-template-columns: 1fr;
  }
  .blog-page-cont img {
    height: 400px;
  }
  .main-blog-section {
    width: 100%;
    padding: 10rem 0;
    position: relative;
  }
  .blog-page-cont h1 {
    font-size: 3.5rem;
  }
  .blogs-grid {
    grid-template-columns: 1fr 1fr;
  }
  .about-section.blogs-main-section {
    padding: 10rem 0 0rem 0;
  }
  .about-c-top h1 {
    font-size: 4.5rem;
  }
  .about-c-top h2 {
    font-size: 3.5rem;
    font-family: "Lato";
    font-weight: 400;

    line-height: 150%;
    text-align: center;
    width: 80%;
  }

  .about-section.contact-section {
    padding: 10rem 0 0 0;
  }
  .about-content {
    gap: 5rem;
  }
  .about-section {
    padding: 10rem 0;
  }
  .about-content img {
    width: 100%;
    margin: 4rem 0;
  }
  .about-flex.service-flex .about-circle1 {
    top: -40%;
  }
  .about-c-top p {
    width: 85%;
  }
  .about-flex-box h3 {
    font-size: 2.25rem;
  }
  .technologies-tabs .nav.nav-tabs .nav-link {
    padding: 1.25rem 2rem;
  }
}

@media only screen and (max-width: 950px) {
  .cs-main-right {
    height: 20rem;
  }
  .hero-content {
    grid-template-columns: 1fr;
  }
  .hero-cont-right {
    margin: 0 auto;
  }
  .cs-main-grid {
    grid-template-columns: 1fr;
  }
  .service-grid-cont {
    grid-template-columns: 1fr;
  }
  .service-content h2 {
    width: 100%;
  }
  .service-graphic-text {
    width: 100%;
  }
  .blogs-grid .blog-main-cont div h4 {
    font-size: 1.75rem;
  }
  .testimonial-cs-box {
    width: 100%;
    max-width: 100%;
    z-index: 5;
  }
  .casestudy-cont.main-cs-page .cs-main-btn h4 {
    font-size: 2.25rem;
  }
  .about-grid {
    grid-template-columns: 1fr 1fr;
  }
  .about-circle1 {
    left: -14px;
    top: -21%;
  }
  .about-circle2 {
    left: -14px;
    top: -21%;
  }
  .about-circle3 {
    left: -14px;
    top: -21%;
  }
  .technologies-box {
    width: 100%;
  }
  .about-flex.service-flex {
    gap: 8rem;
  }
  .technologies-tabContent {
    margin-top: 5rem;
  }
  .technologies-tabContent p {
    width: 80%;
  }
}
@media only screen and (max-width: 850px) {
  .header_right {
    display: none;
  }
  .hr-mob-icon {
    display: flex;
  }
  .contact-grid {
    grid-template-columns: 1fr;
  }
  .contact-grid-chatpdf {
    grid-template-columns: 1fr;
  }
  .technologies-box {
    grid-template-columns: 1fr 1fr;
  }
  .dropdown-box {
    background-color: transparent;
    padding: 2rem 0;
    border-radius: unset;
    gap: 2rem;
    position: relative;
    width: 100%;
    transform: unset;
  }
  .dropdown-box a {
    text-align: center;
    width: 100%;
    font-size: 1.8rem !important;
  }
  .mobile-menu-bottom a {
    text-align: center !important;
  }
}

@media only screen and (max-width: 650px) {
  .box {
    padding: 0 2.5rem;
  }
  .custom-h3 {
    font-size: 2.5rem;
  }
  header {
    padding: 2.5rem 0;
  }
  .hero-section {
    padding: 7.5rem 0;
  }
  .header_cont img {
    width: 90px;
  }
  .footer-content img {
    width: 100%;
    height: auto;
  }
  .hero-cont-left {
    text-align: center;
  }
  .hero-cont-left span {
    width: 100%;
  }
  .hero-cont-left h1 {
    font-size: 2.75rem;
  }
  .hero-cont-left span {
    font-size: 1.6rem;
  }
  .hero-cont-left button {
    margin: 0 auto;
  }
  .hero-cont-left a {
    width: 100%;
  }
  .service-section {
    padding: 5rem 0;
  }
  .service-grid-cont {
    margin: 5rem 0;
  }
  .service-grid-box img {
    width: 50px;
  }
  .service-content h2 {
    font-size: 2.5rem;
  }
  .service-grid-box {
    gap: 1.25rem;
    padding: 2.5rem;
  }
  .service-grid-box h5 {
    font-size: 2.5rem;
  }
  .service-graphic-text h3 {
    font-size: 2rem;
  }
  .service-graphic-text .rect-img {
    width: 60px;
  }
  .service-graphic-text .cir-img {
    width: 64px;
  }
  .team-section {
    padding: 5rem 0;
  }
  .swiper-head {
    margin: 0 0 2.5rem 0;
  }
  .circle-1 {
    display: none;
  }
  .blogs-grid .blog-main-cont.bm-r-cont img {
    height: 100% !important;
    max-height: 25rem;
  }
  .swiper-head h2 {
    font-size: 2.5rem;
  }
  .sh-right svg {
    font-size: 1.65rem;
  }
  .Marquee-Card h3 {
    font-size: 2.8rem;
  }
  .Marquee-Card div {
    width: 50px;
  }
  .getintouch-section {
    padding: 5rem 0;
  }
  .marquee-main-box {
    padding: 2.5rem 0;
  }
  .casestudy-cont {
    gap: 2.5rem;
  }
  .cs-main-left img {
    width: 123px;
  }
  .cs-main-right img {
    border-radius: 0.5rem;
  }
  .blog-cont {
    gap: 2.5rem;
  }
  .casestudy-main {
    gap: 3rem;
  }
  .case-circle1 {
    display: none;
  }
  .case-circle2 {
    display: none;
  }
  .case-circle3 {
    display: none;
  }
  .casestudy-section {
    padding: 0 0 7.5rem 0;
  }
  .casestudy-head h3 {
    font-size: 2.5rem;
  }
  .casestudy-head h6 {
    font-size: 1.6rem;
  }
  .ind-serv-box {
    flex-direction: column;
  }
  .cs-main-btn .inner-service-btn {
    padding: 1.25rem 2rem;
  }
  .cs-main-btn h5 {
    font-size: 1.6rem;
  }
  .cs-main-btn {
    margin-top: 2rem;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
  }
  .cs-main-grid.middle-main-grid {
    padding: 2rem;
  }
  .cs-main-left p {
    font-size: 1.6rem;
  }
  .blog-grid-right {
    grid-template-columns: 1fr;
  }
  .blog-main-cont div h4 {
    text-overflow: ellipsis;
    display: -webkit-box;
    font-family: "Roboto";
    font-size: 1.6rem;
    line-height: 125%;
    width: 267px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .blog-main-cont div {
    gap: 2rem;
    padding: 1.75rem;
  }
  .blogcircle-1 {
    width: 80px;
    height: 80px;
    z-index: -1;
    right: -8%;
    top: 1%;
  }
  .blog-main-cont img {
    border-radius: 0.5rem;
  }
  .blog-main-cont {
    border-radius: 0.5rem;
  }
  .blog-section {
    padding: 5rem 0;
  }
  .faq-section {
    padding: 5rem 0;
  }
  .faq-content h3 {
    font-size: 2.5rem;
    text-align: center;
  }
  .faq-accordian-box {
    padding-top: 2.5rem;
  }
  .faq-accordian-box .accordion-item {
    padding: 2rem;
    border-radius: 0.5rem;
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    margin-bottom: 1.5rem;
  }
  .accordion-item:last-of-type {
    margin-bottom: unset !important;
  }
  .faq-accordian-box .accordion-header h5 {
    font-size: 1.75rem;
  }
  .faq-accordian-box .accordion-body {
    font-size: 1.6rem;
    padding-right: 0;
  }
  .footer-content h5 {
    font-size: 1.5rem;
  }
  .booking-section {
    padding: 0;
  }
  .booking-img {
    display: none;
  }
  .booking-img-chatPdf {
    display: none;
  }
  .git-box {
    transform: unset;
    padding: 3rem 2.5rem;
    max-width: 100%;
    gap: 3rem;
    border-radius: unset;
  }
  .gitsvg-box {
    gap: 2rem;
  }
  .git-b-top h3 {
    font-size: 2.5rem;
  }
  .booking-cont button {
    margin: 0 auto;
  }
  .faqrectangle {
    top: -4%;
    width: 80px;
    height: 80px;
  }
  .service-content a {
    font-size: 1.6rem;
  }
  .gitsvg-box div span {
    font-size: 1.6rem;
  }
  .blog-page-cont img {
    height: 100%;
  }
  .blog-page-cont {
    gap: 3rem;
  }
  .blog-page-cont h1 {
    font-size: 2rem;
  }
  .blog-page-cont h1 br {
    display: none;
  }
  .blog-page-cont p {
    font-size: 1.6rem;
  }
  .about-section.blogs-main-section {
    padding: 7.5rem 0 0rem 0;
  }
  .main-blog-section {
    padding: 7.5rem 0;
  }
  .about-c-top h4 {
    font-size: 1.6rem;
  }
  .about-content {
    gap: 2.5rem;
  }
  .about-c-top h1 {
    font-size: 2.75rem;
  }
  .blogs-grid {
    grid-template-columns: 1fr;
  }
  .about-section.contact-section {
    padding: 7.5rem 0 0 0;
  }
  .cg-left {
    padding: 2rem;
    gap: 2.5rem;
    order: 2;
  }
  .cg-left-form input,
  .cg-left-form textarea {
    padding: 1.25rem 1.65rem;
  }

  .contact-info-box {
    order: 4;
    padding-bottom: 0;
  }

  .cg-right img {
    width: 100% !important;
    height: auto !important;
    border-radius: 1rem !important;
    object-fit: contain;
    margin: 0 !important;
    order: 1;
  }
  .casestudy-cont.main-cs-page {
    padding-top: 7.5rem;
  }
  .steps-to-follow-box {
    padding: 7.5rem 0 2.5rem 0;
  }
  .casestudy-cont.main-cs-page .cs-main-grid .cs-main-right img {
    height: 100%;
  }
  .casestudy-cont.main-cs-page .cs-main-btn {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
  .casestudy-cont.main-cs-page .cs-main-btn h4 {
    font-size: 1.8rem;
    line-height: 150%;
  }
  .main-steps-box {
    gap: 0;
    grid-template-columns: 1fr;
  }
  .steps-to-follow-box img {
    width: 100%;
  }
  .ms-box-content {
    padding: 2rem 2rem 4rem 2rem;
  }
  /* .ms-box-content.msbc-1 {
    border-top: 0;
  } */
  /* .ms-box-content.msbc-3 {
    padding: 4rem 2rem 2rem 2rem;
  } */
  /* .ms-box-content.msbc-2.msbc-4 {
    padding: 4rem 2rem 2rem 2rem;
    border-bottom: 0;
  } */
  .msbc-1 .hor-line {
    display: none;
  }
  .msbc-4 .hor-line {
    display: none;
  }
  .testimonial-cs-box {
    padding: 3rem 2.5rem 2.5rem 2.5rem;
    border-radius: 0.5rem;
  }
  .testimonial-cs-box p {
    font-size: 1.6rem;
  }
  .testimonial-cs-box p img {
    top: -20%;
    left: -5%;
  }
  .about-section {
    padding: 7.5rem 0 5rem 0;
  }
  .testimonial-cs-box div img {
    width: 60px;
  }

  .testimonial-cs-box-component {
    padding: 3rem 2.5rem 2.5rem 2.5rem;
    border-radius: 0.5rem;
  }
  .testimonial-cs-box-component p {
    font-size: 1.6rem;
  }
  .testimonial-cs-box-component p img {
    top: -20%;
    left: -5%;
  }
  .testimonial-cs-box-component div img {
    width: 60px;
  }

  .casestudy-section.cs-section-2 {
    padding: 0;
  }
  .about-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  .contact-info-box {
    gap: 1.5rem;
  }
  .about-grid-content {
    padding: 2.5rem;
    gap: 1rem;
    border-radius: 0.5rem;
  }
  .about-grid-content h5 {
    font-size: 2.2rem;
  }
  .about-content img {
    width: 100%;
    margin: 0 0 2rem 0;
  }
  .about-flex-box {
    grid-template-columns: 1fr;
  }
  .about-circle1 {
    left: -15px;
    top: -12%;
    width: 94px;
    height: 94px;
  }
  .about-circle2 {
    left: -15px;
    top: -12%;
    width: 94px;
    height: 94px;
  }
  .about-circle3 {
    left: -15px;
    top: -12%;
    width: 94px;
    height: 94px;
  }
  .about-circle-3 {
    display: none;
  }
  .about-c-top p {
    width: 100%;
    font-size: 1.6rem;
    margin-top: 1.5rem;
  }
  .about-content.service-content img {
    margin: 0 auto 2rem auto;
  }
  .about-flex.service-flex .about-circle1 {
    left: -15px;
    top: -18%;
    width: 94px;
    height: 94px;
  }
  .about-flex.service-flex .about-circle2 {
    left: -15px;
    top: -18%;
    width: 94px;
    height: 94px;
  }
  .about-flex.service-flex .about-circle3 {
    left: -15px;
    top: -18%;
    width: 94px;
    height: 94px;
  }
  .about-flex-box h3 {
    font-size: 2rem;
  }

  .technologies-content h3 {
    padding-top: 10rem;
    font-size: 2.5rem;
  }
  .technologies-tabContent {
    margin-top: 2.5rem;
    gap: 2.5rem;
  }
  .technologies-tabContent p {
    width: 100%;
    font-size: 1.6rem;
  }
  .technologies-tabs .nav.nav-tabs .nav-link {
    padding: 1.25rem 1.5rem;
    font-size: 1.6rem;
  }
  .technologies-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
  .technologies-section {
    padding: 0 0 0rem 0;
  }
  .main-cs-circle {
    display: none;
  }
  .about-flex-box p {
    font-size: 1.6rem;
  }
  .hero-cont-left p {
    font-size: 1.6rem;
  }
  .booking-cont a {
    width: max-content;
    margin: 0 auto;
  }
  .gradient-btn {
    font-size: 1.6rem;
  }
  .service-grid-box p {
    font-size: 1.6rem;
  }
  .inner-service-btn span {
    font-size: 1.6rem;
  }
  .ind-serv-box span h6 {
    font-size: 1.6rem;
  }
  .ind-serv-box span {
    font-size: 1.6rem;
  }
  .cs-main-left {
    gap: 1rem;
  }
  .cs-main-btn .inner-service-btn span {
    font-size: 1.6rem;
  }
  .git-b-top p {
    font-size: 1.6rem;
  }
  .ms-box-content h4 {
    font-size: 1.8rem;
  }
  .tb-box-img {
    border-radius: 0.5rem;
  }
  .tb-box-img img {
    width: 70px;
  }
  .tb-box-img svg {
    font-size: 6.5rem;
  }
  .blog-main-cont {
    height: unset;
  }
}

@media only screen and (max-width: 550px) {
  .footer-content h5 {
    display: none;
  }
  footer h6 {
    color: var(--color-slate-300);
    font-size: 1.4rem;
    font-weight: 400;
    margin: 3rem 0 0 0;
    display: block;
    text-align: center;
  }
}
@media only screen and (max-width: 450px) {
  .tb-box-img {
    width: 100%;
    max-width: 100%;
  }
}
